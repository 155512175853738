// src/components/StripeCheckout.tsx
import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';

interface StripeCheckoutProps {
  onPaymentSuccess: () => void;
}

export const StripeCheckout: React.FC<StripeCheckoutProps> = ({ onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
  
    if (!cardElement) {
      return;
    }
  
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
  
    if (error) {
      console.log('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
  
      // Send the payment request to your server
      try {
        const response = await axios.post('http://localhost:3001/payment', {
          paymentMethodId: paymentMethod.id,
        });
  
        console.log('[PaymentIntent]', response.data.paymentIntent);
        onPaymentSuccess();
      } catch (error) {
        console.error('Error processing payment:', error);
      }
    }
  };

  if (paymentStatus === 'success') {
    return <p>Payment successful! Certificate is now available for download.</p>;
  }

  if (paymentStatus === 'error') {
    return <p>Payment failed. Please try again.</p>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <h3>Payment Details</h3>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};
