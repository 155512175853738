// src/App.tsx
import React, { useState } from 'react';
import { CertificateForm } from './components/CertificateForm';
import { StripeWrapper } from './components/StripeWrapper';
import { StripeCheckout } from './components/StripeCheckout';
import { generateCertificate } from './utils/generateCertificate';
import styles from './App.module.css';

const App = () => {
  const [userFormData, setUserFormData] = useState<{ firstName: string; lastName: string } | null>(null);
  const [pdfSrc, setPdfSrc] = useState<string | null>(null);

  const handleFormSubmit = (data: { firstName: string; lastName: string }) => {
    setUserFormData(data);
    const certificateDoc = generateCertificate(data.firstName, data.lastName, 'PREVIEW');
    setPdfSrc(certificateDoc.output('datauristring'));
  };

  const handlePaymentSuccess = () => {
    if (userFormData) {
      generateCertificate(userFormData.firstName, userFormData.lastName).save('certificate.pdf');
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Generate Ticket to Heaven</h1>
      <div className={styles.certificateForm}>
        <CertificateForm onSubmit={handleFormSubmit} />
      </div>
      {pdfSrc && <iframe className={styles.pdfPreview} src={pdfSrc} />}
      {userFormData && (
        <div className={styles.stripeWrapper}>
          <StripeWrapper>
            <StripeCheckout onPaymentSuccess={handlePaymentSuccess} />
          </StripeWrapper>
        </div>
      )}
    </div>
  );
};

export default App;
