// src/utils/generateCertificate.ts
import jsPDF from 'jspdf';

export const generateCertificate = (firstName: string, lastName: string, watermark?: string) => {
  const doc = new jsPDF();

  doc.setFontSize(50);
  doc.text('Ticket to Heaven', 105, 40, { align: 'center' });

  doc.setFontSize(32);
  doc.text(`${firstName} ${lastName}`, 105, 80, { align: 'center' });
  doc.text('has purchased their Ticket to Heaven', 105, 100, { align: 'center' });

  if (watermark) {
    doc.setFontSize(42); // Increase the font size
    doc.setTextColor(220, 220, 220);

    // Repeat the watermark across the certificate
    const step = 50;
    for (let x = -200; x < 300; x += step) {
      for (let y = -200; y < 400; y += step) {
        doc.text(watermark, x, y, { angle: 45 });
      }
    }
    doc.setTextColor(0, 0, 0);
  }

  return doc;
};
