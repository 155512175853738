// src/components/CertificateForm.tsx
import React from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  firstName: string;
  lastName: string;
};

interface CertificateFormProps {
  onSubmit: (data: FormData) => void;
}

export const CertificateForm: React.FC<CertificateFormProps> = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm<FormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="firstName">First Name:</label>
      <input id="firstName" {...register('firstName')} />
      <br />
      <label htmlFor="lastName">Last Name:</label>
      <input id="lastName" {...register('lastName')} />
      <br />
      <br />
      <center><button type="submit">Submit</button></center>
    </form>
  );
};
